import {mapActions, mapGetters} from "vuex";

export default {
    props: ['callForm', 'order_id'],
    data(){
        return{
            payload:{
                'order_id':null,
                "number_from":'',
                "number_to":'',
                "type_call": "internal-number-to-external-number"
            },
            loaderStatus: false,
        }
    },
    computed:{
        ...mapGetters({
            profile:'profile/profile',
        })
    },
    created() {
        this.sendCall();
    },
    methods:{
        sendCall() {
            if(this.validate()){
                const payload = {
                    'order_id':this.order_id,
                    "number_from":this.profile.internal_phone_binotel,
                    "number_to":this.callForm.phone,
                    "type_call": "internal-number-to-external-number"
                };
                this.loaderStatus = true;
                setTimeout(() => {

                    this.createCall(payload).then(res=>{
                        if(res.status){
                            this.loaderStatus = false;
                            this.$toasted.success('Дзвінок здійснено успішно!',{
                                duration: 3000
                            })
                            this.closePopup();
                        }else{
                            this.loaderStatus = false;
                            this.$toasted.error(JSON.stringify(res.result.message), {
                                duration: 3000
                            })
                            this.closePopup();
                        }
                    });
                },2500)
            }else{
                this.closePopup();
            }
        },
        validate(){
            if(this.callForm.phone.length !== 10){
                this.$toasted.error('Не правильний номер клиента',{
                    duration: 3000
                })
                return false
            }else if(!this.profile.internal_phone_binotel){
                this.$toasted.error('Не найден внутрешний номер телефона',{
                    duration: 3000
                })
                return false
            }else if(!this.order_id){
                this.$toasted.error('Отсутствует order_id',{
                    duration: 3000
                })
                return false
            }else{
                return true
            }
        },
        closePopup(){
            this.$emit('closePopup');
        },
        ...mapActions({
            getBinotelInfo: 'binotel/getBinotelInfo',
            createCall: "binotel/createCall",
        })
    }
}
